import React, { useState, useEffect, createContext, useContext } from 'react';

// source from https://github.com/streamich/react-use
const isClient = typeof window === 'object';
const useLocalStorage = (key, initialValue, raw) => {
  // if (!isClient) {
  //   return [initialValue, () => {}];
  // }

  const [state, setState] = useState(() => {
    try {
      const localStorageValue = localStorage.getItem(key);

      if (typeof localStorageValue !== 'string') {
        localStorage.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue));
        return initialValue;
      } else {
        return raw ? localStorageValue : JSON.parse(localStorageValue || 'null');
      }
    } catch (_unused) {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });
  useEffect(() => {
    try {
      const serializedState = raw ? String(state) : JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch (_unused2) {
      // If user is in private mode or has storage restriction
      // localStorage can throw. Also JSON.stringify can throw.
    }
  });
  return [state, setState];
};

const Context = createContext();
const TransProvider = _ref => {
  let { i18n, children } = _ref;

  if (!i18n) {
    throw new Error('No i18n provide.');
  }

  const checkLang = (lan) => {
    if (i18n) {
      return i18n[lan] ? true : false;
    }
    return false;
  }
  const [lang, setLang] = useLocalStorage('lang', undefined);
  const [locales, setLocales] = useLocalStorage('locales', undefined);
  useEffect(() => {
    // load lang
    let currentLang = lang;

    console.log('current lang = ' + lang);
    if (!lang || !checkLang(lang)) {
      // const { navigator } = window;

      // if (navigator) {
        // const { language, userLanguage, languages } = navigator;
        currentLang = 'en';
      // }

      console.log('set lang to ' + currentLang);
      setLang(currentLang);
      return;
    } // load locales

    if (
      !locales ||
      !locales.date ||
      !locales.lang ||
      locales.lang !== currentLang ||
      Date.now() - locales.date > 86400000
    ) {
      setLocales({
        data: i18n[currentLang],
        lang: currentLang,
        date: Date.now(),
      });
    }
  });

  const getMessages = () => i18n && i18n[lang] ? i18n[lang] : locales.data;

  const setNewLang = newLang => {
    setLang(newLang);
    setLocales({
      data: i18n[newLang],
      lang: newLang,
      date: Date.now(),
    });
  };

  if (!locales) return React.createElement('div', null, 'loading\u2026');
  return React.createElement(
    Context.Provider,
    {
      value: {
        lang,
        locales,
        getMessages,
        setNewLang,
      },
    },
    children,
  );
};
const useI18n = () => {
  const { getMessages } = useContext(Context);
  return getMessages();
};
const useSetLang = () => {
  const { lang, setNewLang } = useContext(Context);
  return [lang, setNewLang];
};

export { TransProvider, useSetLang, useI18n, useLocalStorage };
