import BigNumber from 'bignumber.js'
import { FEE_RATE_MULTIPLE_TIMES, MIN_ALLAWANCE_AMOUNT, DEFAULT_CHAIN_MAP, BTC_TO_SATOSHI } from './constants';
// import miner from '../assets/all.png';


function getWeb3() {
    var web3;
    if (window.ethereum) {
        web3 = window.ethereum;
    };
    return web3;
}

function formatEther(val) {
    return new BigNumber(val).dividedBy(BTC_TO_SATOSHI);
}

function parseEther(val) {
    return new BigNumber(val).times(BTC_TO_SATOSHI);
}

function getApproveNum() {
    return '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
}

function etherToFixed(num, precision) {
    const formatted = formatEther(num);
    let resBn = new BigNumber(formatted);
    return resBn.toFixed(precision, BigNumber.ROUND_DOWN);
    // return Number(formatEther(num)).toFixed(precision);
}

function getFeeRateDisplay2(val) {
    // the value returned by contract has * 100000000
    return (val * 100 / FEE_RATE_MULTIPLE_TIMES).toFixed(2) + '%';
}

function getFeeRateDisplay3(val) {
    // the value returned by contract has * 100000000
    return (val * 100 / FEE_RATE_MULTIPLE_TIMES).toFixed(0) + '%';
}

function getFeeRateDisplay(val) {
    // the value returned by contract has * 100000000
    return (val / FEE_RATE_MULTIPLE_TIMES).toFixed(1) + 'x';
}

function getFeeRateCent(val) {
    return 100 - (val * 100 / FEE_RATE_MULTIPLE_TIMES).toFixed(0) + '%';
}

function getFeeRate(val) {
    // the value returned by contract has * 100000000
    return (val / FEE_RATE_MULTIPLE_TIMES);
}

function checkAllowance(val) {
    return val > MIN_ALLAWANCE_AMOUNT * (10 ** 18);
}

function calcGasNeeded(hashrate, days, ratio, feePerDay) {
    // not needed
    // return (hashrate * days / 100000000) * ratio * feePerDay;
    return -1;
}

function getRarity(num) {
    return num == 2 ? 'SSR' : (num == 1 ? 'SR' : 'R');
}

function getCurrentTime() {
    return Date.parse(new Date()) / 1000;
}

function format2Digit(v) {
    return ("0" + v).slice(-2);
}

function formatSecondToDHMS(s) {
    if (s == 0) {
        return '0D 0H 0M 0S';
    }
    var day = Math.floor(s / (24 * 3600));
    var hour = Math.floor((s - day * 24 * 3600) / 3600);
    var minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60);
    var second = s - day * 24 * 3600 - hour * 3600 - minute * 60;
    return (day > 0 ? `${day}D ` : '') + format2Digit(hour) + "H " + format2Digit(minute) + "M " + format2Digit(second) + "S";
}

function formatSecondToDHMS2(s) {
    if (s == 0) {
        return '00: 00: 00: 00';
    }
    var day = Math.floor(s / (24 * 3600));
    var hour = Math.floor((s - day * 24 * 3600) / 3600);
    var minute = Math.floor((s - day * 24 * 3600 - hour * 3600) / 60);
    var second = s - day * 24 * 3600 - hour * 3600 - minute * 60;
    return (day > 0 ? `${format2Digit(day)}: ` : '') + format2Digit(hour) + ": " + format2Digit(minute) + ": " + format2Digit(second) + "";
}
function formatSecondToDHMS1(s) {
    if (s == 0) {
        return '0 : 0 : 0';
    }
    // var day = Math.floor( s/ (24*3600) );
    var hour = Math.floor(s / 3600);
    var minute = Math.floor((s - hour * 3600) / 60);
    var second = s - hour * 3600 - minute * 60;
    return `${hour} : ${format2Digit(minute)} : ${format2Digit(second)}`;
}

function formatDateToMMDDYY(timemilis) {
    var time = new Date(timemilis);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = time.getDate();
    d = d < 10 ? "0" + d : d;
    return m + "-" + d + "-" + y;
}

function formatWalletAddress(v) {
    return v.substring(0, 5) + '...' + v.substring(v.length - 5, v.length);
}

function getChainId() {
    const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
    if (env) {
        return DEFAULT_CHAIN_MAP[env].id;
    }
    return DEFAULT_CHAIN_MAP['production'].id;
}

function getChainIdStr() {
    const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
    if (env) {
        return DEFAULT_CHAIN_MAP[env].id_str;
    }
    return DEFAULT_CHAIN_MAP['production'].id_str;
}

function getChainRpc() {
    const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
    if (env) {
        return DEFAULT_CHAIN_MAP[env].rpc;
    }
    return DEFAULT_CHAIN_MAP['production'].rpc;
}

function checkChainId(id) {
    return getChainId() == id;
}

function getErrorMessage(err) {
    const e = eval(err);
    console.log('error:' + JSON.stringify(e));
    let message = e && e.reason;
    // console.log('err reason ' + message);
    if (!message) message = e && e.error && e.error.message;
    // console.log('err msg ' + message);
    if (!message) message = e && e.error && e.error.data && e.error.data.message;
    // console.log('err data msg ' + message);
    if (!message) message = e && e.message;
    // console.log('err final ' + message);
    return message;
}

function hashrate(num) {
    return `${num / 100000000}T`;
}

function sum(num1, num2) {
    return BigNumber.sum(num1.toString(), num2.toString()).toString();
}

function times(num1, num2) {
    return new BigNumber(num1).times(new BigNumber(num2));
}

function minus(num1, num2) {
    return new BigNumber(num1.toString()).minus(num2.toString()).toString();
}

function compare(num1, num2) {
    return new BigNumber(num1.toString()).comparedTo(num2.toString());
}

function raiseGasLimit(num) {
    return parseInt(new BigNumber(num.toString()).times(1.2));
}

function labelPng(type) {
}

function getMinerImage(param) {
    if (param && param.attachmentsRarity) {
        // console.log('img ' + param.attachmentsRarity)
        return `https://www.coolmining.io/static/${param.attachmentsRarity}.png`;
    }
    return null;
}

/* 缩放页面 */
function getWindowsSize(str, id) {
    /* 是windows才会缩放 */
    // console.log(window.screen.width);
    if (/windows|win32/i.test(navigator.userAgent)) {
        /* 暂时规定分辨率横向是1920才允许缩放 */
        if (window.screen.width == 1280) {
            var t = window.devicePixelRatio;   // 获取下载的缩放 125% -> 1.25    150% -> 1.5
            if (t > 1) {
                document.body.style.zoom = 1 / t;   // 就去修改页面的缩放比例
                if (id != 1) {
                    document.getElementsByClassName(str)[0].style.setProperty('height', document.body.scrollHeight + 'px');
                }
            }
        }
    }
}

function floorTo(num, digits) {
    const pow = Math.pow(10, digits);
    let val = Math.floor(num * pow);
    val = val / pow;
    return val.toFixed(digits);
}

function formatMoney(str) {
    if (!str || str.length == 0) return undefined;
    var newStr = "";
    var count = 0;
    if (str.indexOf(".") == -1) {
        //    if(str.charAt(0) == '0'){
        //     str= obj.substring(1);  
        //   }
        for (var i = str.length - 1; i >= 0; i--) {
            if (count % 3 == 0 && count != 0) {
                newStr = str.charAt(i) + "," + newStr;
            }
            else {
                newStr = str.charAt(i) + newStr;
            }
            count++;
        }
        return newStr;
    }
    else {
        for (var i = str.indexOf(".") - 1; i >= 0; i--) {
            if (count % 3 == 0 && count != 0) {
                newStr = str.charAt(i) + "," + newStr;
            }
            else {
                newStr = str.charAt(i) + newStr;
            }
            count++;
        }

        str = newStr + (str + "00").substr((str + "00").indexOf("."), 3);
    }
    return str;

}

function formatTimeDate(e) {
    var date = new Date(e * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
    const Y = date.getFullYear() + '-';
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    const D = date.getDate() + ' ';
    const h = date.getHours() + (date.getHours() < 10 ? '0' : '') + ':';
    const m = date.getMinutes() + (date.getMinutes() < 10 ? '0' : '') + ':';
    const s = date.getSeconds() + (date.getSeconds() < 10 ? '0' : '');
    return (h + m + s);
}

//将毫秒的时间转换成美式英语的时间格式,eg:3rd May 2018
function formatDateEnglishTime(millinSeconds){
	var date = new Date(millinSeconds * 1000);
	var monthArr = new Array("January","February","March","April","May","June","July","August","September","October","November","December");
	var suffix = new Array("st","nd","rd","th");
	
	var year = date.getFullYear(); //年
	var month = monthArr[date.getMonth()]; //月
	var ddate = date.getDate(); //日
	
	if(ddate % 10 < 1 || ddate % 10 > 3) {
		ddate = ddate + '(' + suffix[3] + ')';
	}else if(ddate % 10 == 1) {
		ddate = ddate + '(' + suffix[0] + ')';
	} else if(ddate % 10 == 2) {
		ddate = ddate + '(' + suffix[1] + ')';
	}else {
		ddate = ddate + '(' + suffix[2] + ')';
	}
	return month + " " + ddate + "," + " " +year;
}
function formatDateEnglishTime2(millinSeconds){
    const data = (millinSeconds * 1000) + 172800000 //172800000毫秒是48小时
	var date = new Date(data);
	var monthArr = new Array("January","February","March","April","May","June","July","August","September","October","November","December");
	var suffix = new Array("st","nd","rd","th");
	
	var year = date.getFullYear(); //年
	var month = monthArr[date.getMonth()]; //月
	var ddate = date.getDate(); //日
	
	if(ddate % 10 < 1 || ddate % 10 > 3) {
		ddate = ddate + suffix[3];
	}else if(ddate % 10 == 1) {
		ddate = ddate + suffix[0];
	} else if(ddate % 10 == 2) {
		ddate = ddate + suffix[1];
	}else {
		ddate = ddate + suffix[2];
	}
	return month + " " + ddate;
}
export {
    getWeb3,
    formatEther,
    parseEther,
    getApproveNum,
    getFeeRate,
    getFeeRateDisplay,
    getFeeRateDisplay2,
    calcGasNeeded,
    checkAllowance,
    getRarity,
    getCurrentTime,
    formatSecondToDHMS,
    formatSecondToDHMS1,
    formatSecondToDHMS2,
    formatWalletAddress,
    checkChainId,
    getErrorMessage,
    etherToFixed,
    getFeeRateCent,
    hashrate,
    sum,
    minus,
    times,
    compare,
    getChainIdStr,
    getChainId,
    getChainRpc,
    raiseGasLimit,
    labelPng,
    getMinerImage,
    getWindowsSize,
    getFeeRateDisplay3,
    formatMoney,
    floorTo,
    formatDateToMMDDYY,
    formatTimeDate,
    formatDateEnglishTime,
    formatDateEnglishTime2
}