import React from "react";
import BigNumber from 'bignumber.js'
// import { useContext,} from 'react';
// import AppContext from './store/AppContext';
import {
    HashRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
// import { useI18n, useSetLang  } from './i18n/useI18n';
import test from "./pages/test";
import Header from "./components/Header";
import launchPad from "./pages/launchPad";
import Story from "./pages/Story"
// import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import Roadmap from "./pages/Roadmap";
import 'antd/dist/antd.css';

function App() {
  // const appContext = useContext(AppContext);
  // const t = useI18n();
  // const [lang, setNewLang] = useSetLang();
  BigNumber.config({ EXPONENTIAL_AT: 1000 });
  // const changeLanguage = () => {
  //   if (!lang || lang == 'zh-CN') {
  //     setNewLang('en');
  //   } else {
  //     setNewLang('zh-CN');
  //   }
  // };

  return (
    <div>
      <Router>
      <Header/>
      <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route path="/test" component={test} />
          <Route path="/story" component={Story} />
          <Route path="/roadMap" component={Roadmap} />
          <Route path="/launchPad" component={launchPad} /> */}
      </Switch>
      {/* <Footer/> */}
    </Router>
    </div>
  );
}

export default App;