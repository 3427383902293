import React from 'react'
import { createContext, useState } from 'react'

const AppContext = createContext({
  currentWallet: '',
  chainId: 0,
  provider: null,
  isCreator: false,
  isConnected: false,
  balance: 0,
  totalTokenCount: 0,
  totalTokens: [],
  // coolGasBalance: 0,
  setConnected: (connected) => { },
  setCurrentWallet: (address) => { },
  setWalletProvider: (provider) => { },
  setBalance: (val) => {},
  // setCoolGasBalance: (val) => {},
  setCurrentChainId: (id) => { },
  clearWallet: () => { },
  setTotalTokenCount: (val) => {},
  setTotalTokens: (val) => {},
  setBalance: (val) => {},
  setCreatorFlag: (flag) => { },
})

export function AppContextProvider (props) {
  const [address, setAddress] = useState('')
  const [provider, setProvider] = useState()
  const [isCreator, setIsCreator] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [balance, setEthBalance] = useState(0);
  // const [coolGasBalance, setGasBalance] = useState(0);
  const [chainId, setChainId] = useState(0);
  const [tokenCount, setTokenCount] = useState(0);
  const [tokens, setTokens] = useState([]);

  function setCurrentWallet (address) {
    setAddress(address)
  }
  
  function setCurrentChainId (id) {
    // console.log('set chainid to ' + id)
    setChainId(id)
  }

  function setWalletProvider (provider) {
    setProvider(provider)
  }

  function setCreatorFlag (flag) {
    setIsCreator(flag)
  }

  function setConnected (flag) {
    setIsConnected(flag)
  }

  function setBalance (val) {
    setEthBalance(val)
  }

  function setTotalTokenCount (val) {
    setTokenCount(val)
  }

  function setTotalTokens (val) {
    setTokens(val)
  }

  // function setCoolGasBalance (val) {
  //   setGasBalance(val)
  // }

  function clearWallet () {
    setAddress('')
    setProvider('')
    setIsCreator(false)
    setConnected(false)
    setBalance(0)
    setTokens([]);
    setTokenCount(0);
    // setCoolGasBalance(0)
    // setCurrentChainId(0);
  }

  const context = {
    currentWallet: address,
    provider: provider,
    isCreator: isCreator,
    isConnected: isConnected,
    totalTokenCount: tokenCount,
    totalTokens: tokens,
    // coolHashBalance: coolHashBalance,
    balance: balance,
    chainId: chainId,
    // method
    setCurrentWallet: setCurrentWallet,
    setWalletProvider: setWalletProvider,
    clearWallet: clearWallet,
    setCreatorFlag: setCreatorFlag,
    setConnected: setConnected,
    setCurrentChainId: setCurrentChainId,
    setBalance: setBalance,
    setTotalTokens: setTotalTokens,
    setTotalTokenCount: setTotalTokenCount
    // setCoolGasBalance: setCoolGasBalance
  };

  return (
    <AppContext.Provider value={context}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContext;
