import React from 'react';
import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import mempoolJS from "@mempool/mempool.js";
import Loading from '../components/Loading';
import useWallet from "../hooks/useWallet";
import useOrdinal from "../hooks/useOrdinal";
import AppContext from '../store/AppContext';
// import Jazzicon from 'react-jazzicon'
import { bytesToHex, isValidTaprootAddress, btcToSats, satsToBtc, textToHex, textChecksum } from '../utils/brcUtils';
import { Button, Space, Table, Tag, message, Progress, notification, Radio } from 'antd';
import { getCurrentTime, getErrorMessage, formatSecondToDHMS2, formatTimeDate, minus, times, compare, etherToFixed, formatMoney, floorTo, formatWalletAddress, formatDateEnglishTime, formatDateEnglishTime2 } from '../utils';
import useInterval from "../hooks/useInterval";
import useFetch from 'use-http';
import api from "../utils/api";
import { USER_REMAINED_TIME_REFRESH_INTERVAL, CONFIG_MAP, getConfigs, WALLET_GET_GAS_REFRESH_INTERVAL } from '../utils/constants';

import "../css/launchPad.css"

import TW from '../assets/TW.png';
import TG from '../assets/TG.png';
import DC from '../assets/DC.png';
import WEB from '../assets/WEB.png';
import btcCoin from '../assets/btcCoin.png';
import logo from '../assets/front/TopQ.png';
import Q from '../assets/front/Q.png'
import Q1 from '../assets/front/Q1.png'
import btccoin from '../assets/front/BTC.png'
import Food from '../assets/front/food.png'
import ccss from '../assets/front/3.png'
import ccss1 from '../assets/front/1.png'
import ccss2 from '../assets/front/2.png'
import ccss3 from '../assets/front/4.png'
import proQ from '../assets/front/ProQ.png'
import btoDuck from '../assets/front/BQ.png'

const PROJ_STATUS_STR = ['INCOMING', 'IN PROGRESS', 'IN PROGRESS', 'ENDED'];
const TIME_STATUS_STR = ['WILL START IN : ', 'ENDS IN : ', 'ENDS IN : ', 'ENDED'];
const PROJ_STATUS_TEXT = ['WhiteList Sale', 'WhiteList Sale', 'Public Sale', 'Public Sale'];
const DEFUALT_PROJ_ID = 1;
const REFRESH_RATIO_INTERVAL = 30000;//300000 for prod;
const PRECISION_FIX = 1000000;
const RATIO_100_PERCENT = 1000000;
function LaunchPad() {
    const appContext = useContext(AppContext);
    const prjId = DEFUALT_PROJ_ID;

    // 0: not start; 1: in progress; 2: ended, 3: archived ?
    const [projectStatus, setProjectStatus] = useState(0);
    const [tokenName, setTokenName] = useState();
    const [amount, setAmount] = useState(0);
    const [butnDisabled, setButnDisabled] = useState(true);
    const [claimGas, setClaimGas] = useState(0);
    const [hasData, setHasData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);

    const [currentSupply, setCurrentSupply] = useState(0);
    const [hardCap, sethardCap] = useState(0);
    const [softCap, setSoftCap] = useState(0);
    const [price, setPrice] = useState(0);
    const [ratio, setRatio] = useState(RATIO_100_PERCENT);
    const [projectInfo, setProjectInfo] = useState();
    const [userInvestInfo, setUserInvestInfo] = useState();
    const [listData, setListData] = useState();
    const [minimumPurchase, setMinimumPurchase] = useState(0);
    const [totalSupply, setTotalSupply] = useState(0);
    const [projectName, setProjectName] = useState('');
    const [fundraisers, setFundraisers] = useState(0);
    const [fundraisingAmount, setFundraisingAmount] = useState(0);
    const [myFundraisingAmount, setMyFundraisingAmount] = useState(0);
    const [priceUsd, setPriceUsd] = useState(0);
    const [maxPurchase, setMaxPurchase] = useState(0);
    const [tokenForPresale, setTokenForPresale] = useState(0);
    const [prgBg, setPrgBg] = useState(0);
    const [myWalletMoney, setMyWalletMoney] = useState(0);
    const [fastestFee, setFastestFee] = useState(0);
    const [halfHourFee, setHalfHourFee] = useState(0);
    const [hourFee, setHourFee] = useState(0);
    const [userChooseFee, setUserChooseFee] = useState(0);
    const [claimAble, setClaimAble] = useState(0);
    const [claimAbleBack, setClaimAbleBack] = useState(0);
    const [refundAmount, setRefundAmount] = useState(0);
    const [signLength, setSignLength] = useState(0);
    const [claimEnableTimestamp, setClaimEnableTimestamp] = useState(0);
    const [satChooseBorder, setSatChooseBorder] = useState(2);
    const [softCapFullShow, setSoftCapFullShow] = useState(false);

    const unisat = (window).unisat;

    const [saleStartTime, setSaleStartTime] = useState(0);
    const [toStartTime, setToStartTime] = useState();
    const [saleEndTime, setSaleEndTime] = useState(0);
    const [toEndTime, setToEndTime] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonLoadingClaim, setButtonLoadingClaim] = useState(false);
    const [butnDisabledClaim, setButnDisabledClaim] = useState(false);
    const [refundAddress, setRefundAddress] = useState('');
    const [depositAddress, setDepositAddress] = useState('');
    const [gasDepositAddress, setGasDepositAddress] = useState('');
    const [stage, setStage] = useState('');
    const [whiteListStartTimestamp, setWhiteListStartTimestamp] = useState('');
    const [whiteListEndTimestamp, setWhiteListEndTimestamp] = useState('');
    const [publicStartTimestamp, setPublicStartTimestamp] = useState('');
    const [publicEndTimestamp, setPublicEndTimestamp] = useState('');

    const [isWalletLegal, setIsWalletLegal] = useState(true);
    const [connect, initWallet, switchNetwork, signPsbt, signMessage, pushTx, pushPsbt, sendBtc, workaroundForUnisat] = useOrdinal(unisat);
    const { get, post, response, apiloading, apierror } = useFetch('');
    const fetchList = useFetch('');
    let history = useHistory();

    // const { Address, Script, Signer, Tap, Tx } = window.tapscript;
    const [txid, setTxid] = useState("");
    // const cryptoUtils = window.cryptoUtils;

    // refresh the countdown and project status every 1 second
    let refreshInterval = useInterval(async () => {
        const currentTime = getCurrentTime()
        if (currentTime < whiteListStartTimestamp) {
            if (whiteListStartTimestamp == 0) return;
            setToStartTime(calcRemainedTime(whiteListStartTimestamp))
            setProjectStatus(0)
        } else if (whiteListStartTimestamp < currentTime && currentTime < whiteListEndTimestamp) {
            if (whiteListEndTimestamp == 0) return;
            setToStartTime(calcRemainedTime(whiteListEndTimestamp))
            setButnDisabled(false)
            // inviteAddress(appContext.currentWallet)
            setProjectStatus(1)
        } else if (whiteListEndTimestamp < currentTime && currentTime < publicEndTimestamp) {
            if (publicEndTimestamp == 0) return;
            setProjectStatus(2)
            setButnDisabled(false)
            setToStartTime(calcRemainedTime(publicEndTimestamp))
        } else if (publicEndTimestamp < currentTime) {
            setProjectStatus(3)
            if (fundraisingAmount < softCap) {
                setSoftCapFullShow(true)
                setClaimAble(0)
                setButnDisabledClaim(true)
            }
        }
        // console.log(projectStatus);
        // checkTimePhase();

    }, USER_REMAINED_TIME_REFRESH_INTERVAL);

    // refresh the project dilutionRate and list every 300 seconds
    // let refreshRatioInterval = useInterval(async () => {
    //     // console.log('refresh stats!');
    //     await getProjStats(prjId);
    //     getInvestList(prjId, appContext.currentWallet || '');
    // }, REFRESH_RATIO_INTERVAL);

    let refreshGetGas = useInterval(async () => {
        const { bitcoin: { fees } } = mempoolJS({
            hostname: 'mempool.space'
        });
        const feesRecommended = await fees.getFeesRecommended();
        setFastestFee(feesRecommended.fastestFee)
        setHalfHourFee(feesRecommended.halfHourFee)
        setHourFee(feesRecommended.hourFee)
    }, WALLET_GET_GAS_REFRESH_INTERVAL)


    async function getGasFun() {
        const { bitcoin: { fees } } = mempoolJS({
            hostname: 'mempool.space'
        });
        const feesRecommended = await fees.getFeesRecommended();
        // console.log(feesRecommended);
        setFastestFee(feesRecommended.fastestFee)
        setHalfHourFee(feesRecommended.halfHourFee)
        setHourFee(feesRecommended.hourFee)
    }

    const bindChild = (e) => {
        setLoading(e)
    }

    const checkInput = (e) => {
        const maxLength = 10;
        e.target.value = e.target.value.replace(/[^\d.]/g, '').replace('.', '$')
            .replace(/\./g, '')
            .replace('$', '.').slice(0, maxLength);
        // if (e.target.value.length > maxLength) e.target.value = e.target.value.slice(0, maxLength);
    }

    useEffect(() => {
        getProjInfo(prjId, appContext.currentWallet);
        getGasFun()
        // getProjStats(prjId);
        // getEstimateGas();
        // qqqq()

    }, []);
    useEffect(() => {
        getUserCliamStatus()
    }, [myFundraisingAmount])
    // async function qqqq() {
    //     const data = {}
    //     const res = await post(api.POST_SEND_CLAIM_TX(), data);
    //     if (res) {
    //          console.log('res' + res);   
    //     }
    // }
    useEffect(() => {
        const currentTime = getCurrentTime()
        // check wallet address
        if (appContext.currentWallet) {
            getwalletMoney()
            if (whiteListStartTimestamp < currentTime && currentTime < whiteListEndTimestamp) {
                inviteAddress(appContext.currentWallet)
            }
            // 判断钱包格式合不合法
            const isValid = isValidTaprootAddress(appContext.currentWallet, getConfigs(CONFIG_MAP).encodePrefix, true);
            if (!isValid) {
                setIsWalletLegal(false);
                showNotification('Invalid wallet address, only P2WPKH and Taproot formats are supported!', true);
            } else {
                setIsWalletLegal(true);
            }
        }
        fetchList.abort();
        // console.log('getInvestList for ', appContext.currentWallet);
        // getInvestList(prjId, appContext.currentWallet || '');
        getProjInfo(prjId, appContext.currentWallet);
        getUserCliamStatus()

    }, [appContext.currentWallet]);

    async function inviteAddress(address) {
        // console.log(address);
        const result = await get(api.GET_WHITE_LIST(address));
        if (result) {
            if (!result.data) {
                showNotification('Sorry you are not in the whitelist, please wait for the public sale.')
                setButnDisabled(true)
            } else {
                setButnDisabled(false)
            }
        }
    }

    async function getwalletMoney() {
        const { address, balance } = await initWallet();
        setMyWalletMoney(balance.total)
        // console.log('initWallet ', JSON.stringify(balance));
    }

    function showNotification(content, long = false) {
        // message.error(content);
        notification.open({
            message: '',
            description: content,
            duration: long ? 8 : 3
        });
    }

    function calcRemainedTime(startTime) {
        let now = getCurrentTime();
        if (startTime > now) {
            // console.log('startTime > now');
            return formatSecondToDHMS2(startTime - now);
        }
        if (startTime < now) {
            // console.log('startTime < now');
            return formatSecondToDHMS2(0);
        }
        return formatSecondToDHMS2(startTime - now);
    }

    function isProjectStatusValid() {
        return projectStatus != undefined;
    }
    function isProjectClaimable() {
        return projectStatus == 2;
    }
    function isProjectProcessing() {
        return projectStatus < 2;
    }
    function isUserCanClaim() {
        return appContext.isConnected && appContext.currentWallet && userInvestInfo && userInvestInfo.totalAmount != 0;
    }
    function isItemHilight(index) {
        if (!userInvestInfo || !userInvestInfo.count) return false;
        return userInvestInfo.count > index;
    }
    function estimateTokenAmount(btcAmt) {
        if (!btcAmt || !price || btcAmt < 0) return 0;
        const ratio1 = ratio;
        let tokenAmt = parseFloat(btcAmt) * ratio1 / (price * RATIO_100_PERCENT);
        return formatMoney(Math.floor(tokenAmt).toString());
    }
    function calcClaimable() {
        if (currentSupply < softCap) return 0;
        if (!userInvestInfo || userInvestInfo.totalAmount == 0) return 0;
        return estimateTokenAmount(userInvestInfo.totalAmount);
    }
    function calcRefundBtc() {
        // if (currentSupply >= softCap && currentSupply < hardCap) return 0;
        if (!userInvestInfo || userInvestInfo.totalAmount == 0) return 0;
        // project soft cap not reached
        console.log('currentSupply =', currentSupply, ratio)
        if (currentSupply < softCap) {
            return userInvestInfo.totalAmount;
        }
        return floorTo((RATIO_100_PERCENT - ratio) * userInvestInfo.totalAmount / RATIO_100_PERCENT, 8);
    }

    // api calls
    async function getProjInfo(id, address) {
        // setLoading(true);
        const result = await get(api.GET_PROJECT_INFO(id, address));
        if (result) {
            // console.log('getProjInfo result: ' + JSON.stringify(result))
            if (result.data) {
                const data = result.data;
                setProjectInfo(data);
                setTokenName(data.tokenName);
                setTokenName(data.tokenName);
                sethardCap(data.hardCap);
                setSoftCap(data.softCap);
                setMinimumPurchase(data.minimumPurchase);
                setTotalSupply(data.totalSupply)
                setProjectName(data.projectName)
                setPrice(data.price);
                setGasDepositAddress(data.gasDepositAddress)
                setDepositAddress(data.depositAddress)
                setStage(data.stage)
                setFundraisers(data.fundraisers)
                setMaxPurchase(data.maxPurchase)
                setTokenForPresale(data.tokenForPresale)
                setFundraisingAmount(data.fundraisingAmount)
                setMyFundraisingAmount(data.myFundraisingAmount)
                setPriceUsd(data.priceUsd)
                setRefundAmount(data.refundAmount)
                setSignLength(data.signLength)
                setClaimAbleBack(data.dueTokenCount)
                setClaimAble(data.dueTokenCount)
                setWhiteListStartTimestamp(data.whiteListStartTimestamp)
                setWhiteListEndTimestamp(data.whiteListEndTimestamp)
                setPublicStartTimestamp(data.publicStartTimestamp)
                setPublicEndTimestamp(data.publicEndTimestamp)
                setClaimEnableTimestamp(data.claimEnableTimestamp)

                if (Number(data.fundraisingAmount) > Number(data.hardCap)) {
                    setPrgBg(100);
                } else {
                    setPrgBg((data.fundraisingAmount / data.hardCap) * 100);
                }
                if(data.myFundraisingAmount == 0){
                    setButnDisabledClaim(true)
                }
            }

        }
        setLoading(false);
    }

    async function getUserCliamStatus() {
        const addr = appContext.currentWallet;
        const resRepet = await get(api.GET_CHECK_CLAIMED(addr, prjId));
        // console.log(resRepet);
        if (resRepet.data) {
            console.log('claimed');
            setClaimAble(0)
            setButnDisabledClaim(true)
        } else {
            console.log('no claimed');
            getProjInfo(prjId, appContext.currentWallet)
            setButnDisabledClaim(false)
        }
    }
    async function getProjStats(id) {
        const result = await get(api.GET_PROJECT_STATS(id));
        if (result && result.data) {
            setCurrentSupply(result.data.actualRaise);
            const current = result.data.actualRaise;
            const total = result.data.hardCap;
            const r = current > total ? total * RATIO_100_PERCENT / current : RATIO_100_PERCENT;
            console.log('backend dilutionRate: ' + result.data.dilutionRate * RATIO_100_PERCENT + ', front ratio = ' + r)
            setRatio(result.data.dilutionRate * RATIO_100_PERCENT);
        }
    }
    async function getEstimateGas() {
        const result = await get(api.GET_ESITMATE_GAS());
        if (result) {
            // console.log('getEstimateGas result: ' + JSON.stringify(result))
            if (result.data) {
                setClaimGas(result.data.estimateGasFee)
            }
        }
    }
    async function getInvestList(id, addr) {
        setTableLoading(true);
        const result = await fetchList.get(api.GET_INVEST_LIST(id, addr));
        if (result) {
            if (result.data) {
                setUserInvestInfo(result.data.statVO);
                setListData(result.data.listVO);
            }
        }
        setTableLoading(false);
    }
    function getDisplayedCountdown() {
        if (projectStatus == 0) return toStartTime;
        return null;
    }
    const confirmSend = async () => {
        // 有无安装钱包
        if (!window.unisat) {
            showNotification("Please install Unisat wallet!");
            return;
        }
        if (!appContext.currentWallet) {
            showNotification('Please connect wallet');
            return
        }
        if (whiteListStartTimestamp < getCurrentTime() && getCurrentTime() < whiteListEndTimestamp) {
            inviteAddress(appContext.currentWallet)
        }
        if (!amount || parseFloat(amount) <= 0 || !amount.replaceAll('.', '')) {
            showNotification("Invalid amount!");
            return;
        }
        // 金额是否大于最最值
        if (btcToSats(amount) > (btcToSats(maxPurchase) - btcToSats(myFundraisingAmount))) {
            showNotification(`Operation failed. You can only invest an amount between ${minimumPurchase}BTC and ${maxPurchase}BTC.`);
            return
        }
        // 小于
        if(myFundraisingAmount == 0){
            if (amount < minimumPurchase * PRECISION_FIX / PRECISION_FIX) {
                showNotification(`Operation failed. You can only invest an amount between ${minimumPurchase}BTC and ${maxPurchase}BTC.`);
                return;
            }
        }

        console.log('amount ', amount)
        //if (type == 1) {
        // add many notification
        // 钱包是否合法
        if (!isWalletLegal) {
            showNotification("The wallet type is not supported!");
            return;
        }

        //}
        const amt = amount;
        if (!appContext.isConnected) {
            await connect();
            return;
        }
        if (!amt) return;
        console.log('pay amount ', amt)
        const addr = depositAddress;
        await sendBitcoin(addr, amt);
    }
    const sendBitcoin = async (addr, amount) => {
        setButtonLoading(true);
        console.log('sendBitcoin ', btcToSats(amount))
        try {
            const { txid, error } = await workaroundForUnisat(sendBtc,
                addr,
                btcToSats(amount),
            );
            console.log('sendBitcoin ', txid, error)
            if (txid) {
                setTxid(txid);
                showNotification('Success!');
            }
            if (error) {
                showNotification(error);
            }
        } catch (e) {
            setTxid((e).message);
            showNotification(e.message);
        }
        setButtonLoading(false);
    }
    const claim = async () => {
        if (!window.unisat) {
            showNotification("Please install Unisat wallet!");
            return;
        }
        if (!appContext.isConnected) {
            await connect();
            return;
        }
        if ((userChooseFee * signLength) > myWalletMoney) {
            showNotification("Insufficient balance");
            return
        }
        const chooseFee = satChooseBorder == 1 ? hourFee : satChooseBorder == 2 ? halfHourFee : satChooseBorder == 3 ? fastestFee : 0
        const addr = appContext.currentWallet;
        setButtonLoadingClaim(true);
        const res = await get(api.GET_CLAIM_INFO(addr, prjId, chooseFee));
        console.log('claim info ', JSON.stringify(res));
        if (res.data) {
            const data = res.data;
            if (data.claimed) {
                showNotification("You have claimed, please wait for a minute.");
                setButtonLoadingClaim(false);
                return;
            }
            if (data.sign) {
                await signPsbtAndPush(addr, data.sign);
            }
        } else {
            showNotification(res.msg);
        }
        setButtonLoadingClaim(false);
    }
    const signPsbtAndPush = async (addr, sign) => {
        console.log('signpsbt ', sign)
        try {
            const { result, error } = await workaroundForUnisat(signPsbt,
                sign
            );
            console.log('signpsbt result ', result, error)
            if (result) {
                const hex = result;
                const { result: res, error: err } = await pushPsbt(hex);
                console.log('pushpsbt ', res, 'error: ' + JSON.stringify(err))
                if (err) {
                    showNotification(err.message);
                }
                if (res) {
                    const data = {
                        claimHash: res,
                        projectId: prjId,
                        walletAddress: addr
                    };
                    const ress = await post(api.POST_SEND_CLAIM_TX(), data);
                    if (ress) {
                        setClaimAble(0)
                        setButnDisabledClaim(true)
                    }
                    showNotification('Success!');
                }
            }
            if (error) {
                showNotification(error);
            }
        } catch (e) {
            showNotification(e.message);
        }
    }
    const goView = (tx) => {
        const url = 'https://mempool.space/' + getConfigs(CONFIG_MAP).mempool + 'tx/' + tx;
        window.open(url);
    }

    async function clickMaxBtn() {
        if (!appContext.isConnected) {
            return;
        }
        if ((btcToSats(maxPurchase) - btcToSats(myFundraisingAmount)) > myWalletMoney && myWalletMoney !== 0) {
            setAmount(satsToBtc(myWalletMoney))
        } else {
            if ((btcToSats(maxPurchase) - btcToSats(myFundraisingAmount)) >= btcToSats(maxPurchase)) {
                setAmount(maxPurchase)
            } else {
                if ((btcToSats(maxPurchase) - btcToSats(myFundraisingAmount)) > 0) {
                    setAmount(satsToBtc((btcToSats(maxPurchase) - btcToSats(myFundraisingAmount))))
                }
            }
        }
    }
    const tofixedNumFun = (x) => {
        var f = parseFloat(x);
        if (isNaN(f)) {
            return false;
        }
        var f = Math.round(x * 100) / 100;
        var s = f.toString();
        var rs = s.indexOf('.');
        if (rs < 0) {
            rs = s.length;
            s += '.';
        }
        while (s.length <= rs + 2) {
            s += '0';
        }
        return s;
    }
    const chooseGasFun = (e) => {
        setSatChooseBorder(e)
        if (e == 1) {
            setUserChooseFee(hourFee)
        } else if (e == 2) {
            setUserChooseFee(halfHourFee)
        } else if (e == 3) {
            setUserChooseFee(fastestFee)
        }
    }

    return (
        <div className='contentPad'>
            <div className="launchPad">
                <div className='partOne flex'>
                    <div className='partOneL flex'>
                        <div className='upImg flex'>
                            <img src={logo}></img>
                        </div>
                        <div className='downIcon flex'>
                            <a href='https://discord.gg/openfoodie' target='_blank'><img className='dc' src={DC} /></a>
                            <a href='https://twitter.com/openfoodieNFT' target='_blank'><img className='tw' src={TW} /></a>
                            <a href='https://linktr.ee/openfoodie' target='_blank'><img className='web' src={WEB} /></a>
                        </div>
                    </div>
                    <div className='partOneR flex'>
                        <div className='context'>Bitcoin Foodie is the genesis series of Open Foodie, with a total supply of 256 NFTs. </div>
                        <div className='context'>$f00d is the native token of the Open Foodie BTC ecosystem and is used to incentivize and reward community members for their contributions. </div>
                        <div className='context'>You can earn $f00d by participating in $f00d IDO or by holding a Bitcoin Foodie NFT.</div>
                        <div className='context'>
                            <div className='textOne'>Total Supply: {formatMoney(totalSupply.toString())}</div>
                            <div className='textTwo'>Token for IDO: {formatMoney(tokenForPresale.toString())}</div>
                        </div>
                    </div>
                </div>
                <div className='partTwoOutSide'>
                    <div className='partTwo'>
                        <div className='foodTitle'>
                            <img src={Q}></img>
                            <div className='cctt'>
                                <div className='ccttLogo'>$f00d</div>
                                <div className={projectStatus == 2 ? 'statusPublic' : ''}>{PROJ_STATUS_TEXT[projectStatus]}</div>
                            </div>
                        </div>
                        <img src={ccss} className='scellection'></img>
                        <div className='statusTime flex'>
                            <div className={projectStatus == 0 ? 'status statusIncoming' : projectStatus == 3 ? 'statusEnded' : 'status'}>{PROJ_STATUS_STR[projectStatus]}</div>
                            {TIME_STATUS_STR[projectStatus] !== 'ENDED' && <div className='startTime'>{TIME_STATUS_STR[projectStatus]}{toStartTime}</div>}
                        </div>
                        <div className='progcss'>
                            <div className='innerBg' style={{ width: `${prgBg}%`, transition: '0.5s all' }}></div>
                            {(prgBg !== 0 && prgBg >= 10) && <div className='innerBtc' style={{ left: `${prgBg - 15}%`, transition: '0.5s all' }} >{tofixedNumFun(fundraisingAmount)}BTC</div>}
                            {(prgBg !== 0 && prgBg < 10) && <div className='innerBtc innerBtc2' style={{ left: `${prgBg + 1.5}%`, transition: '0.5s all' }} >{tofixedNumFun(fundraisingAmount)}BTC</div>}
                            {prgBg !== 0 && <img className='innerCCTT' src={proQ} style={{ left: `${prgBg - 2}%`, transition: '0.5s all' }}></img>}
                        </div>
                        <div className='BtcNum flex'>
                            <div>{0} BTC</div>
                            <div style={{ marginRight: '11vw' }}>{softCap} BTC</div>
                            <div>{hardCap} BTC</div>
                        </div>
                    </div>
                </div>
                <div className='partThree flex'>
                    <div className='totalContent flex'>
                        <div className='totalLeft'>
                            <div className='option'>
                                <div className='opKey'>Token Total Supply </div>
                                <div className='opVal'>{totalSupply}</div>
                            </div>
                            <div className='option'>
                                <div className='opKey'>Token for IDO </div>
                                <div className='opVal'>{tokenForPresale}</div>
                            </div>
                            <div className='option'>
                                <div className='opKey'>Initial Token Offering Price-BTC </div>
                                <div className='opVal'>{price}BTC</div>
                            </div>
                            <div className='option'>
                                <div className='opKey'>Initial Token Offering Price-USD </div>
                                <div className='opVal'>{priceUsd}$</div>
                            </div>
                        </div>
                        <div className='totalRight'>
                            <div className='option'>
                                <div className='opKey'>Number of fundraisers </div>
                                <div className='opVal'>{fundraisers}</div>
                            </div>
                            <div className='option'>
                                <div className='opKey'>Number of my fundraising amount </div>
                                <div className='opVal'>{myFundraisingAmount}BTC</div>
                            </div>
                            <div className='option'>
                                <div className='opKey'>Number of tokens obtained </div>
                                <div className='opVal'>{claimAbleBack}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bottomBgColor'>
                    {projectStatus !== 3 && <div className='partFour flex'>
                        <div className='buyContent'>
                            <div className='numInput'>
                                <div className='btcScope flex'>{minimumPurchase} BTC - {maxPurchase} BTC</div>
                                <img className='QQQ' src={Q1}></img>
                                <div className='maxBtn' onClick={clickMaxBtn}>MAX</div>
                                <img className='btcIcon' src={btccoin}></img>
                                <input className='inputBtc' type='number' onInput={(e) => { if (e.target.value.length > 10) e.target.value = e.target.value.slice(0, 10) }} maxLength={10} value={amount} onChange={(e) => {
                                    setAmount(e.target.value);
                                }}></input>
                            </div>
                            <Button className='buyBtns flex' loading={buttonLoading} disabled={butnDisabled} onClick={confirmSend}>BUY</Button>
                        </div>
                    </div>}
                    {projectStatus == 3 && <div className='claimePart flex'>
                        {getCurrentTime() > claimEnableTimestamp && <div className='upShowData flex'>
                            <div className='claimAble'>CLAIMABLE: {claimAble}</div>
                            <div className='refundAmount'>REFUND AMOUNT: {refundAmount} BTC</div>
                        </div>}
                        {getCurrentTime() < claimEnableTimestamp && <div className='upShowData claimEnableTimestamp'>Claim Will Start In : {formatTimeDate(claimEnableTimestamp) + ',' + formatDateEnglishTime(claimEnableTimestamp)}</div>}
                        <div className='downShowData flex'>
                            <div className={satChooseBorder == 1 ? 'chooseGasOption activeBorder' : 'chooseGasOption'} onClick={() => { chooseGasFun(1) }}>
                                <div className='upShow'>LOW PRIORITY</div>
                                <div className='downShow'><span>{hourFee}</span>  SAT/BYTE</div>
                            </div>
                            <div className={satChooseBorder == 2 ? 'chooseGasOption activeBorder' : 'chooseGasOption'} onClick={() => { chooseGasFun(2) }}>
                                <div className='upShow'>MIDDLE PRIORITY</div>
                                <div className='downShow'><span>{halfHourFee}</span>  SAT/BYTE</div>
                            </div>
                            <div className={satChooseBorder == 3 ? 'chooseGasOption activeBorder' : 'chooseGasOption'} onClick={() => { chooseGasFun(3) }}>
                                <div className='upShow'>HIGH PRIORITY</div>
                                <div className='downShow'><span>{fastestFee}</span>  SAT/BYTE</div>
                            </div>
                        </div>
                        {getCurrentTime() < claimEnableTimestamp && <Button className='buyBtns buyBtnsgrey'>CLAIM</Button>}
                        {getCurrentTime() > claimEnableTimestamp && <Button className='buyBtns' loading={buttonLoadingClaim} disabled={butnDisabledClaim} onClick={claim}>CLAIM</Button>}
                    </div>}
                    <div className='bottomCard flex'>
                        <div className='foodTitle'>
                            <img src={Q}></img>
                            {!softCapFullShow && <div className='cctt'>
                                <div className='ccttLogo'>$f00d</div>
                                <div>{PROJ_STATUS_TEXT[2]}</div>
                            </div>}
                        </div>
                        {!softCapFullShow && <div className='cardRight'>
                            {(PROJ_STATUS_TEXT[projectStatus] == 'WhiteList Sale') && <div className='aaa'>{PROJ_STATUS_STR[0]}</div>}
                            {(PROJ_STATUS_TEXT[projectStatus] !== 'WhiteList Sale') && <div className='aaa'>{PROJ_STATUS_STR[projectStatus]}</div>}
                            {(TIME_STATUS_STR[projectStatus] !== 'ENDED' && PROJ_STATUS_TEXT[projectStatus] !== 'WhiteList Sale') && <div className='bbb'>{TIME_STATUS_STR[projectStatus]}{toStartTime}</div>}
                            {(PROJ_STATUS_TEXT[projectStatus] == 'WhiteList Sale') && <div className='bbb'>{TIME_STATUS_STR[0]}{toStartTime}</div>}
                            {/* {PROJ_STATUS_TEXT[projectStatus] == 'INCOMING' && <div className='bbb'>{TIME_STATUS_STR[0]}{calcRemainedTime(whiteListEndTimestamp)}</div>} */}
                        </div>}
                        {softCapFullShow && <div className='softNotFull'>
                            <div className='fullUptext'>All the funds from your investments will be fully refunded to your wallet on <span>{formatDateEnglishTime2(publicEndTimestamp)}.</span></div>
                            <div className='fullDowntext'>Thank you for your participation.</div>
                        </div>}
                        <img className='bottomDuck' src={ccss3}></img>
                        <img className='bottomDuck1' src={ccss1}></img>
                        {/* {TIME_STATUS_STR[projectStatus] !== 'ENDED' && <img className='bottomDuck2' src={ccss2}></img>} */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LaunchPad;
