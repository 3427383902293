import React, { useRef } from "react";
import BigNumber from 'bignumber.js'
import Header from "../components/Header";
import "../css/home.css"
import AudioPanel from '../components/Audio'

import { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from '../store/AppContext';

import banner from '../assets/website/banner.png'
import person1 from '../assets/website/person1.png'
import person2 from '../assets/website/person2.png'
import person3 from '../assets/website/person3.png'
import oAo from '../assets/website/oAo.png'
import story from '../assets/website/STORY.png'
import about_1 from '../assets/website/ABOUT 1.png'
import about_2 from '../assets/website/ABOUT 2.png'
import about_3 from '../assets/website/ABOUT 3.png'


function Home() {
  const appContext = useContext(AppContext);

  let playerRef = useRef(null)
  let history = useHistory();
  let player = null;
  let isPlaying = false;

  useEffect(() => {
    window.addEventListener('click', onResize, true)
    // window.addEventListener('scroll', onResize, true)
    return () => {
      window.removeEventListener('click', onResize, false)
      // window.removeEventListener('scroll', onResize, false)
    }
  }, [])

  const onResize = useCallback(() => {
    if (player && !isPlaying) {
      player.play();
    }
  }, []);

  function onInit(ap) {
    player = ap;
    player && player.on('playing', function () {
      //   console.log('player start');
      isPlaying = true;
    });

    playerRef.current = ap
  };

  const togglePlayer = () => {
    if (isPlaying) {
      playerRef.current.pause()
      isPlaying = false;
    } else {
      playerRef.current.play()
      isPlaying = true;
    }
  }

  return (
    <div className="homeBg">
      <div className="aboutBanner" onClick={ togglePlayer }>
        <img src={banner}></img>
      </div>
      <div className="moduleTwo flex">
        <img src={about_1}></img>
        <img src={person1} className="person1"></img>
      </div>
      <div className="oAo">
        <img src={oAo}></img>
      </div>
      <div className="fouthMoudel">
        <div className="about_2">
          <img src={about_2}></img>
          <img className="person2" src={person2}></img>
        </div>
        <div className="about_3">
          <img src={about_3}></img>
          <img className="person3" src={person3}></img>
        </div>
      </div>
      {/* <div className="aboutIntr">
        <img src={story}></img>
      </div> */}
      <div className="audioMp3">
        <AudioPanel onInit={onInit}></AudioPanel>
      </div>
    </div>
  );
}

export default Home;