import React from 'react';
import ReactAplayer from 'react-aplayer';
import music from '../assets/website/FOODIE官网音乐.mp3'

export default class App extends React.Component {
  // event binding example
  onPlay = () => {
    // console.log('on play');
  };

  onPause = () => {
    // console.log('on pause');
  };

  // example of access aplayer instance
  onInit = ap => {
    if (this.props.onInit) {
      this.props.onInit(ap);
    }
  };

  render() {
    const props = {
      theme: '#F57F17',
      lrcType: 3,
      audio: [
        {
          name: 'HODOR',
          artist: 'HODOR',
          autoplay: true,
          url: music,
        //   cover: 'https://moeplayer.b0.upaiyun.com/aplayer/hikarunara.jpg',
        //   lrc: 'https://moeplayer.b0.upaiyun.com/aplayer/hikarunara.lrc',
          theme: '#ebd0c2'
        }
      ]
    };

    return (
      <div>
        <ReactAplayer
          {...props}
          onInit={this.onInit}
          onPlay={this.onPlay}
          onPause={this.onPause}
        />
        {/* example of access aplayer instance API */}
        {/* <button onClick={() => this.ap.toggle()}>toggle</button> */}
      </div>
    );
  }
}