import React from "react";
import { useState, useContext, useEffect, useCallback } from 'react';
import AppContext from '../store/AppContext';
import ConnectButton from "./ConnectButton";
import { useI18n, useSetLang } from '../i18n/useI18n';
import "../css/cover.css"
import { Link, useHistory } from 'react-router-dom';

import TT from '../assets/website/twitter.svg'
import DC from '../assets/website/discord.svg'
import OS from '../assets/website/magic eden LOGO.svg'
import IDOicon from '../assets/website/logo.png'
import TG from '../assets/website/telegram.svg';


function Header() {
    //0-home 1-Launchpad 2-Roadmap 3-Apply
    const [state, setState] = useState(0);
    const history = useHistory();
    useEffect(() => {
        //刷新页面时 导航文字的选中效果不会默认回到首页
        let hash = window.location.hash;
        let reg1 = new RegExp("#/","g");
        var hash1 = hash.replace(reg1,"");
        if(hash1 == "launchpad"){
            setState(1);
        }else if(hash1 == "roadmap"){
            setState(2);
        }else{
            setState(0);
        }
    }, []);

    return (
        <div>
            <div className="common-header flexpub">
                <Link to={'/'} onClick={()=>{setState(0); history.replace('/');}}>
                    <div className="title">
                        <img src={IDOicon} alt=""/>
                        {/* <span>SATSALE</span> */}
                    </div>
                </Link>
                <div className="nav flexpub">
                    {/* <Link to={'/'} onClick={()=>{setState(0);history.replace('/');}} className={state == 0 ? 'stateActive' : ''}>ABOUT</Link> */}
                    {/* <Link to={'/story'} onClick={()=>{setState(1);history.replace('/story');}} className={state == 1 ? 'stateActive' : ''}>STORY</Link> */}
                    {/* <Link to={''} onClick={()=>{setState(2)}} className={state == 2 ? 'stateActive' : ''}>ROADMAP</Link>
                    <Link to={''} onClick={()=>{setState(3)}} className={state == 3 ? 'stateActive' : ''}>TEAM</Link>
                    <Link to={''} onClick={()=>{setState(4)}} className={state == 4 ? 'stateActive' : ''}>MINT</Link> */}
                    <a href="https://twitter.com/openfoodienft" target="_blank"><img src={TT} className="TT"></img></a>
                    <a href="https://t.me/open_foodie" target="_blank"><img src={TG} className="DC"></img></a>
                    <a href="https://magiceden.io/ordinals/marketplace/bitcoin-foodie" target="_blank"><img src={OS} className="OS"></img></a>
                </div>
                {/* <div>
                    <ConnectButton />
                </div> */}
            </div>
        </div>
    );
}

export default Header;