import React from "react";
import { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { getWeb3, formatWalletAddress } from '../utils';
import Loading from './Loading';
import AppContext from '../store/AppContext';
import { useI18n } from '../i18n/useI18n';
import "../css/connectButton.css"
import walletConneted from '../assets/wallet.png';
// https://mempool.space/api/v1/fees/recommended
function ConnectButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const appContext = useContext(AppContext);
  const t = useI18n();

  const [address, setAddress] = useState();
  const [isDroppedDown, setDropDown]=useState(false);
  const [showModal, setShowModal] = useState(false);
  const [noUnisat, setNoUnisat] = useState(false);

  const [connected, setConnected] = useState(false);
  const [accounts, setAccounts] = useState([]);

  let history = useHistory();

  const bindModal=(e)=>{
    setShowModal(e)
  }

  const selfRef = useRef({
    accounts: [],
  });

  const self = selfRef.current;
  const handleAccountsChanged = (_accounts) => {
    if (self.accounts[0] === _accounts[0]) {
      return;
    }
    self.accounts = _accounts;
    if (_accounts.length > 0) {
      setAccounts(_accounts);
      setConnected(true);
      appContext.setConnected(true);
      appContext.setCurrentWallet(_accounts[0]);
      setAddress(_accounts[0]);
    } else {
      setConnected(false);
      setAddress('');
      appContext.setConnected(false);
      appContext.setCurrentWallet('');
    }
  };

  useEffect(() => {
    const unisat = (window).unisat;
    // console.log('unisat ' + unisat);
    if (!unisat) {
      return;
    }
    unisat.getAccounts().then((accounts) => {
      console.log('account changed ', accounts);
      handleAccountsChanged(accounts);
    });

    unisat.on("accountsChanged", handleAccountsChanged);

    return () => {
      unisat.removeListener("accountsChanged", handleAccountsChanged);
    };
  }, []);

  const disconnect = () => {
    setDropDown(false);
  }

    return (
      <div className="dropdown">
        <div className={!provider || !address ? "connect-button" : "connect-button connect-button-connected"}
          onClick={async () => {
            if (!connected || !address) {
              if (noUnisat) {
                window.open("https://unisat.io/download");
                return;
              }
              if (typeof window.unisat == 'undefined') {
                console.log('UniSat Wallet is not installed!');
                setNoUnisat(true);
                return;
              }
              const unisat = window.unisat;
              console.log('UniSat Wallet connecting');
              const data = await unisat.requestAccounts();
              console.log('requestAccounts result', data);
              // unisat.keepAlive();
              handleAccountsChanged(data);
            } else {
              if (isDroppedDown) {
                setDropDown(false);
              } else {
                setDropDown(true);
              }
            }
          }}
        >
          {(address) && <img className="wallet-icon" src={walletConneted}/>}
          {(!connected || !address) ? (noUnisat ? 'Please install Unisat' : 'Wallet') : `${formatWalletAddress(address)}` }
        </div>
        
        {/* <Loading type="modal" loading={showModal} setLoading={bindModal.bind(this)} title={t.fail} text={t.metamask}></Loading> */}
      </div>
    );
  }

  export default ConnectButton;