import React from "react";
import BigNumber from 'bignumber.js'
import Header from "../components/Header";
import "../css/home.css"
import AudioPanel from '../components/Audio'

import { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import AppContext from '../store/AppContext';

import banner from '../assets/website/banner.png'
import person1 from '../assets/website/person1.png'
import person2 from '../assets/website/person2.png'
import person3 from '../assets/website/person3.png'
import oAo from '../assets/website/oAo.png'
import story from '../assets/website/STORY.png'
import about_2 from '../assets/website/ABOUT 2.png'
import about_3 from '../assets/website/ABOUT 3.png'


function Story() {

  return (
    <div className="homeBg">
      <div className="aboutIntr">
        <img src={story}></img>
      </div>
    </div>
  );
}

export default Story;