const i18n = {
    'en': {
      connect: 'Wallet',
      disconnect: 'Disconnect',
      success: 'Success',
      fail: 'Fail',
      earn:'Invite To Earn',
      share:'3 steps to win',
      cup:'World Cup profits !',
      dollar:'$670,000,000,000',
      super:'Super web3 project born in the 2022 World Cup',
      web3:'The game-changing Sportsbook in Web3.',
      connectW:'Please Connect Wallet First',
      copy:'Copy',
      available:'You are NOT AVAILABLE',
      claimNFT:'Please Mint Your NFT First',
      getBNS:'Get a BNS first and Join Again',
      buyBNS:'Buy BNS',
      willget:'Your friends will get',
      freeNFT:'A FREE NFT',
      youcan:'And you can',
      upgradeNFT:'UPGRADE YOUR NFT',
      howto:'How to Invite',
      getlink:'Free mint DEWIN PASS',
      step:'Step',
      friend:'Get a referral link&Invite ur friend',
      getNFT:'Invite ur friend',
      mint:'Mint',
      minted:'Minted',
      list:'Referral List',
      dashboard:'Invite Dashboard',
      activeF:'Active Friends',
      total:'Total Invite',
      address:'Wallet Address',
      date:'Sign Date',
      noData:'NO DATA',
      pending:'Pending',
      status:'Ranking Status',
      NFTLevel:'Your Current NFT Level',
      rank:'Rank',
      level:'Level',
      here:'You Are Here',
      alert:'Alert',
      alertAll:'Your rank is currently below 70% of your Current NFT Level',
      about:'ABOUT',
      aboutAll:'DEWIN is a permissionless prediction platform determined to provide convenient, fair and transparent prediction services for the upcoming 2022 World Cup, which is predicted to be worth over $670 billion in 2022 based on $580 billion market size in 2018. these services were traditionally provided by black-box centralized agencies. If a decentralized web3 solution can gain 10% market share, DEWIN can get as much as $67 billion in cash flow and $3.3 billion in profits. These profits will be distributed fairly to all DEWIN NFT holders through smart contracts.',
      FAQ:'FAQ',
      FAQ1:'What is the referral system?',
      FAQ1text:'Become a valid user - Invite friends to join - Friends become valid users - Upgrade NFT - Share the projected profit of DEWIN according to the level of NFT.',
      FAQ2:'Who qualifies for a freemint PASS NFT?',
      FAQ2text:'DEWIN does not charge any mint fees or transaction royalties. The gas required for completing the transaction goes to the network not DEWIN.',
      FAQ3:'How is the level of NFT determined?',
      FAQ3text:'The level of NFT takes into account of the number of successfully invites and all subsequent valid invites. The more users you invite get validated, the higher the level of your NFT will be. The higher the level of NFT, the higher the percentage of profit you will get in DEWIN in the future.',
      FAQ4:'Is the level of NFT permanent or dynamic?',
      FAQ4text:'The level of DEWIN’s PASS NFT invitations is<br/>dynamic. It goes up or down according to the<br/>ranking of all invited relations in DEWIN. there<br/>are 5 levels of PASS NFT in DEWIN.<br/>NATION PASS (300 total): each generation<br/>requires 3 GREEN FIELD PASSes under the invitation<br/>relationship<br/>GREEN FIELD PASS (900 total): 3 CLUB PASSes per<br/>generated invitation required relationship;<br/>CLUB PASS (2700 total): 3 SUPER STAR PASSes per<br/>generated invitation required relationship;<br/>SUPER STAR PASS (8100 total): 3 FANS PASSes per<br/>generated invitation required relationship;<br/>FANS PASS (no upper limit for total number): A valid user<br/>must have a BNS in a wallet.',
      FAQ6:'What is a valid user?',
      FAQ6text:'In order to prevent spam transactions, a user must have a BNS domain name (Binance Name Service) to be considered as a valid user.',
      FAQ5:'How to define the invitation relationship and what is a valid invitation?',
      FAQ5text:'The invited address must be a valid user, and also freemint a DEWIN PASS after being invited.',
      FAQ7:'How long does the campaign last?',
      FAQ7text:'DEWIN’s sharing campaign ends after the 2022 World Cup, during which profit sharing activities are still processing.',
      FAQ8:'How does DEWIN work?',
      FAQ8text:'DEWIN is a fully decentralized smart contract that will provide completely fair and transparent betting results during the World Cup. DEWIN charges a 5% transaction fee to the winners in the prize pool, and its trading profits are distributed equally to all early contributors who support DEWIN, with their contributions rewarded according to the NFT level.',
      FAQ9:'How is the fairness of DEWIN guaranteed?',
      FAQ9text:'Unlike centralized prediction systems that are rife with corruption, fraud and RUGs, DEWIN is an open-sourced decentralized system that is subject to the strictest audits. The developers of DEWIN have initiated all permissions and the contracts will run automatically on the chain. All DEWIN contributors will receive the most lucrative and transparent rewards.',
      FAQ10:'What are the roadmap for DEWIN and will there be any token issuance plans?',
      FAQ10text:'DEWIN has an ambitious development plan in the prediction market. In addition to FIFA, DEWIN plans to have a major presence in all ecologies of the prediction market, such as game result prediction, campaign prediction, etc. The global prediction market is a trillion- dollar market and DEWIN will continue to provide absolute fairness and transparency through a decentralized system. Roadmap and token offering plans will be announced in the future.',
      FAQ11:"How to contact us?",
      FAQ11text:"We welcome any community or individual to contact us directly at the official email address: support@dewin.bet for questions or suggestions for improvement. We believe in community governance and endogenous motivation as the cornerstone of Dewin.",
      china:'Chinese',
      english:'English',
      korean:'Korean',
      more:'More',
      nation:'Nation:',
      field:'Green Field:',
      club:'Club:',
      supersrar:'SuperStar:',
      fans:'Fans:',
      partner:'PARTNER',
      media:'MEDIA',
      rm:'Read More',
      roadmap:'ROADMAP',
      phrase1:'Phrase 1  Launch',
      phrasetext1:'·Deployment of DeWin PASS evolutionary system <br/>·Deployment of official website and social media <br/>·Basic function realization and tutorial <br/>·Cooperate with famous organizations <br/>·Deployment of Dewin.bet basic contract <br/>·Deployment of Dewin.Bet oracle machine <br/>·Set up Dewin Dao community <br/>·Official publicity and promotion during the World Cup <br/>·Dewin evolution season launch <br/>·Promotion with famous organizations <br/>·Upgrade Dewin.bet contract <br/>·Upgrade Dewin pass rules <br/>·Dewin lists on major exchanges',
      phrase2:'Phrase 2  Growth',
      phrasetext2:'·Upgrade Dewin.bet gameplay<br/>·Deployment of Dewin multiple competitions modules contract<br/>·Continued sponsorship and exposure to prestigious competitions<br/>·Add non-mainstream/current affair/e-sports prediction modules<br/>·Dewin lists on top exchanges<br/>·Deployment of loan module contract<br/>·Dewin Pass functional design<br/>·Economic system upgrade Dewin functions<br/>·Dewin deflation mechanism launch<br/>·Dewin is sponsored as player independent bonus<br/>·Dewin Dao deploy prediction contact independently<br/>·Set up Metaverse based on Dewin Pass',
      phrase3:'Phrase 3  Built',
      phrasetext3:'·Cooperate with more NFT communities and organizations<br/>·More Dewin spreaders<br/>·The predictive contract autonomously deployed on Dewin becomes the first choice for any hot event<br/>·Superstars are joining Dewin<br/>·Comprehensive cooperation with organizations within the industry<br/>·Improvement of Dewin payment and Defi<br/>·Dewin get involved widely in major media and report<br/>·Dewin Joins major hot events predictions actively<br/>·Dewin agreement profits are more equitably distributed to Dewin PASS<br/>·Improvement and ecosystem construction of Dewin Metaverse',
      claimtoken: 'Claim $DEWIN',
      connectfirst: 'Please Connect Wallet First',
      notinairdrop: 'Sorry, you\'re not qualified for the Airdrop.',
      back: 'Go Back',
      claimnow: 'Congratulations, you can claim your airdrop now.',
      claim: 'Claim',
      airdroprule: '$DEWIN Airdrop Rules:<br/>From now on to the Season 1 Opening Ceremony, the first 10,000 minted FANS PASS addresses, namely valid addresses, are allowed to participate in $DEWIN Airdrop activity, and each valid address can claim 1,000 $DEWIN tokens.<br/>Each valid address only has one opportunity to claim those $DEWIN, which cannot be claimed in batches.<br/>$DEWIN Airdrop is to celebrate the FIFA World Cup Qatar 2022™ and is not related to the DeWin PASS upgrade, etc.<br/>For any questions, please contact the official email address <a href="mailto:support@dewin.bet">support@dewin.bet</a>'
    },
    'chi':{
      connect: 'Wallet',
      disconnect: 'Disconnect',
      success: '成功',
      fail: '失败',
      earn:'Invite To Earn',
      share:'3步分享',
      cup:'世界杯利润！',
      dollar:'6700亿美元',
      super:'2022卡塔尔世界杯体育博彩利润！',
      web3:'改变游戏规则的Web3体育游戏',
      connectW:'请连接钱包',
      copy:'Copy',
      available:'您无法参与',
      claimNFT:'请先铸造NFT',
      getBNS:'先获取一个BNS再来参加活动吧',
      buyBNS:'Buy BNS',
      willget:'你的朋友可免费铸造',
      freeNFT:'一个NFT',
      youcan:'且可升级',
      upgradeNFT:'您的NFT',
      howto:'How to Invite',
      getlink:'Free mint DEWIN PASS',
      step:'Step',
      friend:'Get a referral link&Invite ur friend',
      getNFT:'Invite ur friend',
      mint:'Mint',
      minted:'Minted',
      list:'推广名单',
      dashboard:'邀请统计',
      activeF:'Active Friends',
      total:'Total Invite',
      address:'Wallet Address',
      date:'Sign Date',
      noData:'NO DATA',
      pending:'Pending',
      status:'Ranking Status',
      NFTLevel:'Your Current NFT Level',
      rank:'Rank',
      level:'Level',
      here:'You Are Here',
      alert:'Alert',
      alertAll:'Your rank is currently below 70% of your Current NFT Level',
      about:'ABOUT',
      aboutAll:'DEWIN 是一个完全去中心化的预测平台，决心为即将来临的2022年世界杯提供便捷、公平、透明的去中心化服务。2018年世界杯的预测金额超过5800亿美元，而2022年其规模可能达到6700亿美元。在过去，这些服务基本上是由中心化的不可信平台提供的。倘若去中心化的web3解决方案能够获取10%的市场份额，DEWIN就能够获取高达670亿美元的资金流水和33亿美元的高额利润。这些利润都将通过智能合约公平地分发给所有DEWIN的NFT持有者。',
      FAQ:'FAQ',
      FAQ1:'基本邀请机制是什么？',
      FAQ1text:'基本机制：成为有效用户——邀请朋友加入——朋友成为有效用户——升级NFT——依据NFT的级别分享DEWIN的预测利润。',
      FAQ2:'什么条件可以freemint PASS NFT？',
      FAQ2text:'任意有效地址都可以freemint NFT.DEWIN 不收取任何mint费用和交易版税。当然，基于区块链所必须的gas并非DEWIN收取。',
      FAQ3:'NFT的级别是如何产生的？',
      FAQ3text:'被邀请并mint PASS NFT的用户，以及其后续发生的所有间接的有效邀请关系，都是其NFT升级的有效计算范围内。你邀请的有效用户越多，你的NFT的等级就会依据竞争排名（DEWIN RANK）而上升，NFT的级别就越高，未来在DEWIN中获取的利润分成比例越高。',
      FAQ4:'NFT的级别是永久的还是动态的？',
      FAQ4text:'DEWIN的邀请PASS NFT的级别是动态的。根据<br/>DEWIN中所有邀请关系的排名判断。<br/>DEWIN 的PASS NFT 一共有5个级别：<br/>国家队PASS（总量300个）：每个生成需要邀请关系下有3个绿茵场PASS；<br/>绿茵场PASS（总量900个）：每个生成需要邀请关系下有3个俱乐部PASS；<br/>俱乐部PASS（总量1800个）：每个生成需要邀请关系下有3个球星PASS;<br/>球星PASS（总量8100个）：每个生成需要邀请关系下有3个球迷PASS；<br/>球迷PASS（无上限）：持有任意BNS域名即可视为有效用户。',
      FAQ6:'什么是有效用户？',
      FAQ6text:'为防止垃圾交易，任意用户持有任何BNS域名的地址（Binance Name Service）即可认定为有效用户。',
      FAQ5:'怎样认定邀请关系，什么是有效邀请？',
      FAQ5text:'被邀请地址必须是有效用户，且在被邀请后同样freemint了一个DEWIN PASS。',
      FAQ7:'活动持续多久？',
      FAQ7text:'DEWIN的分享活动在2022年世界杯结束后停止，期间仍然开展利润分享活动。',
      FAQ8:'DEWIN是怎样运作的，利润如何产生？',
      FAQ8text:'DEWIN是一个完全去中心化的智能合约，会在世界杯期间提供完全公平和透明的竞猜结果。所有基于比赛结果的预测投入将锁定在合约之中。DEWIN在奖池中向赢家收取5%的交易税，其交易利润将以去中心化的方式分发给所有支持DEWIN的早期贡献者，其贡献依照NFT的级别予以奖励。',
      FAQ9:'怎样保障DEWIN的公平性？',
      FAQ9text:'不同于中心化的预测系统，充斥着腐败、欺诈和RUG。DEWIN是完全开源的去中心化系统，接受了最严格的审计。DEWIN的开发者已发起了所有权限，合约将在链上自动运行。所有DEWIN的贡献者将获得最丰厚和最透明的回报。',
      FAQ10:'DEWIN后续有何发展计划，是否会有代币发行计划？',
      FAQ10text:'DEWIN在预测市场有着宏伟的发展计划。除FIFA外，DEWIN将在预测市场各个生态已有重大布局，例如游戏比赛结果预测、竞选预测等。全球预测市场规模达数万亿美元，DEWIN将通过去中心化的系统将持续提供绝对公平和透明的服务。DEWIN的roadmap和代币发行计划将会在未来公布。',
      FAQ11:"如何联系我们？",
      FAQ11text:"欢迎任何社区或个人直接向官方邮箱联系：support @dewin.bet 向我们提出问题或改进性建议。我们坚信社区的自组织和内生动力是Dewin 的基石。",
      china:'中文',
      english:'英文',
      korean:'韩文',
      more:'更多',
      nation:'Nation:',
      field:'Green Field:',
      club:'Club:',
      supersrar:'SuperStar:',
      fans:'Fans:',
      partner:'合作伙伴',
      media:'新闻',
      rm:'更多',
      roadmap:'ROADMAP',
      phrase1:'Phrase 1  Launch',
      phrasetext1:'DeWin PASS进化体系部署<br/>官网和社交媒体部署<br/>基本功能实现与教程<br/>与知名机构合作<br/>DeWin.Bet基础合约部署<br/>DeWin.Bet预言机部署<br/>Dewin社区DAO建立<br/>世界杯期间的正式宣传和推广<br/>DeWin进化赛季开启<br/>知名机构合作与推广<br/>Dewin BET合约升级<br/>Dewin PASS规则升级<br/>Dewin上线主流交易所',
      phrase2:'Phrase 2  Growth',
      phrasetext2:'Dewin BET玩法升级<br/>Dewin部署多种赛事板块合约<br/>向著名赛事持续赞助曝光<br/>增加非主流/时事热点/电子竞技预测板块<br/>重点交易所上线Dewin<br/>Dewin借贷板块合约部署<br/>Dewin PASS 功能性设计<br/>经济系统升级Dewin功能<br/>DEWIN通缩机制启动<br/>Dewin作为球员独立奖金赞助<br/>Dewin DAO可以独立部署预测合约<br/>基于Dewin PASS的Metaverse建立',
      phrase3:'Phrase 3  Built',
      phrasetext3:'与更广泛的NFT社区和机构合作<br/>更多的Dewin推广者<br/>自主部署在Dewin上的预测合约<br/>成为任何热点事件的第一选择<br/>超级巨星纷纷加入Dewin与行业内机构更广泛的合作<br/>Dewin 支付和Defi的完善<br/>Dewin更广泛地介入主流媒体和报道<br/>Dewin积极参与重大热点事件的预测<br/>Dewin协议利润更公平地分发给Dewin PASS<br/>Dewin Metaverse的完善和生态建设',
      claimtoken: '领取$DEWIN',
      connectfirst: '请先连接钱包',
      notinairdrop: '抱歉，该钱包地址无法领取空投奖励',
      back: '返回',
      claimnow: '恭喜，您可领取空投奖励',
      claim: '领取',
      airdroprule: '$DEWIN 空投活动规则：<br/>· 即日起至Season 1开启仪式日，前10,000个铸造FANS PASS的地址，即有效地址，可在官网参与本活动，每个有效地址可领取1,000枚$DEWIN 代币；<br/>· 每个有效地址仅可领取一次，不可分批领取；<br/>· $DEWIN空投活动是迎接卡塔尔世界杯的独立活动，与DeWin PASS升级等无关。<br/>· 任何疑问请联系唯一官方邮箱<a href="mailto:support@dewin.bet">support@dewin.bet</a>'
    },
    'kor':{
      connect: 'Wallet',
      disconnect: 'Disconnect',
      success: '성공',
      fail: 'Fail',
      earn:'수입을 초대하십시오',
      share:'공유 할 3 단계',
      cup:'월드컵 수익!',
      dollar:'$670,000,000,000',
      super:'2022 카타르 월드컵의 스포츠 베팅 이익',
      web3:'Web3의 게임 변화 스포츠 북.',
      connectW:'먼저 지갑을 연결해주세요',
      copy:'Copy',
      available:'당신은 사용할 수 없습니다',
      claimNFT:'Please Claim Your NFT First',
      getBNS:'먼저 BNS를 받고 다시 가입하세요!',
      buyBNS:'Buy BNS',
      willget:'Your friends will get',
      freeNFT:'A FREE NFT',
      youcan:'And you can',
      upgradeNFT:'UPGRADE YOUR NFT',
      howto:'어떻게 초대합니까',
      getlink:'무료주조하다 DEWIN PASS',
      step:'Step',
      friend:'추천 링크 받기 & 친구를 초대',
      getNFT:'친구를 초대',
      mint:'Mint',
      minted:'Minted',
      list:'추천 목록',
      dashboard:'초대 대시보드',
      activeF:'활동적인 친구',
      total:'총 초대',
      address:'Wallet Address',
      date:'Sign Date',
      noData:'데이터 없음',
      pending:'보류 중',
      status:'순위 현황',
      NFTLevel:'현재 NFT 레벨',
      rank:'순위',
      level:'레벨',
      here:'당신은 여기에있다',
      alert:'알리다',
      alertAll:'귀하의 순위는 현재 현재 NFT 레벨의 70% 미만입니다',
      about:'ABOUT',
      aboutAll:'DEWIN은 다가오는 2022년 월드컵을 위해 편리하고 공정하며 투명한 탈중앙화 서비스를 제공하기로 결정된 완전 탈중앙화 예측 플랫폼입니다. 2018년 월드컵에는 5,800억 달러 이상의 도박이 있었고 2022년에는 6,700억 달러에 달할 수 있습니다. 과거에는 이러한 서비스가 기본적으로 중앙 집중식의 신뢰할 수 없는 플랫폼에서 제공되었습니다. 분산형 web3 솔루션이 10%의 시장 점유율을 확보할 수 있다면 DEWIN은 최대 670억 달러의 현금 흐름과 33억 달러의 고수익을 얻을 수 있습니다. 이 수익은 스마트 계약을 통해 모든 DEWIN NFT 보유자에게 공정하게 분배됩니다.',
      FAQ:'FAQ',
      FAQ1:'기본 초대 메커니즘은 무엇입니까？',
      FAQ1text:'기본 메커니즘:유효한 사용자 되기 - 친구 초대하기 - 친구가 유효한 사용자 되기 - NFT 업그레이드 - NFT 수준에 따라 DEWIN의 예상 수익 공유.',
      FAQ2:'freemint PASS NFT의 조건은 무엇입니까？',
      FAQ2text:'모든 유효한 주소는 NFT를 freemint할 수 있습니다.DEWIN은 조폐료 및 거래 로열티를 청구하지 않습니다. 물론 블록체인에 필요한 가스는 DEWIN에서 청구하지 않습니다.',
      FAQ3:'NFT의 수준은 어떻게 생성됩니까？',
      FAQ3text:'초대되고 PASS NFT를 발행하는 사용자와 모든 후속 간접 유효 초대 관계는 NFT 업그레이드의 유효 계산 범위 내에 있습니다. 더 많은 유효 사용자를 초대할수록 경쟁 순위(DEWIN RANK)를 기반으로 NFT 순위가 높아집니다.',
      FAQ4:'NFT의 수준은 영구적입니까,아니면 동적입니까？',
      FAQ4text:'DEWIN의 PASS NFT 초대 수준은 동적입니다.<br/>DEWIN의 모든 초대 관계의 순위를 기준으로 위 또는 아래로 이동합니다.<br/>DEWIN의 PASS NFT에는 총 5개의 레벨이 있습니다.<br/>국가대표 PASS(총 300개) : 초대 관계에 따라 세대당 3개의 그린필드 PASS.<br/>필요그린필드 PASS(총 900개): 초대 관계에 따라 세대당 3개의 클럽 PASS가 필요합니다.<br/>클럽 패스(총 2700개): 초대 관계에 따라 세대당 3성 패스 필요 스타패스 (총 8100개) : 초대 관계에 따라 세대당 3개의 팬 PASS가 필요합니다.<br/>Fan PASS (금액 상한 없음) : 유효한 사용자는 지갑에 BNS가 있어야 합니다',
      FAQ6:'효과적인 사용자란 무엇입니까？',
      FAQ6text:'스팸 거래를 방지하기 위해 BNS 도메인 이름 주소(Binance Name Service)를 보유한 모든 사용자를 유효한 사용자로 식별할 수 있습니다.',
      FAQ5:'초대 관계를 식별하는 방법, 유효한 초대는 무엇입니까？',
      FAQ5text:'초대된 주소는 유효한 사용자여야 하며 초대된 후 DEWIN PASS도 프리민트해야 합니다.',
      FAQ7:'이벤트 기간은 얼마나 됩니까？',
      FAQ7text:'DEWIN의 나눔 활동은 2022년 월드컵 이후 중단되며, 이 기간 동안에도 수익 나눔 활동은 계속됩니다.',
      FAQ8:'DEWIN은 어떻게 작동하며 어떻게 수익을 창출합니까？',
      FAQ8text:'DEWIN은 월드컵 기간 동안 완전히 공정하고 투명한 베팅 결과를 제공하는 완전히 분산된 스마트 계약입니다. 경기 결과를 기반으로 예측된 ​​모든 입력은 계약에 잠깁니다. DEWIN은 당첨자에게 상금 풀에서 5%의 거래세를 부과하고 거래 수익은 DEWIN을 탈중앙화 방식으로 지원하는 모든 초기 기여자에게 분배되며 그들의 기여는 NFT 수준에 따라 보상됩니다.',
      FAQ9:'DEWIN의 공정성을 보장하는 방법은 무엇입니까？',
      FAQ9text:'중앙 집중식 예측 시스템과 달리 부패, 사기 및 RUG로 가득 차 있습니다. DEWIN은 가장 엄격한 감사를 거친 완전 오픈 소스 분산 시스템입니다. DEWIN의 개발자는 모든 권한을 시작했으며 계약은 체인에서 자동으로 실행됩니다. 모든 DEWIN 기여자는 가장 관대하고 투명한 보상을 받게 됩니다.',
      FAQ10:'DEWIN의 향후 개발 계획은 무엇이며 토큰 발행 계획은 있습니까',
      FAQ10text:'DEWIN은 예측 시장에서 웅대한 개발 계획을 가지고 있습니다. FIFA 외에도 DEWIN은 게임 경쟁 결과 예측, 선거 예측 등 예측 시장의 다양한 생태계에서 주요 레이아웃을 갖게 됩니다. 글로벌 예측 시장의 규모는 수조 달러에 달하며 DEWIN은 계속해서 탈중앙화 시스템을 통해 절대적으로 공정하고 투명한 서비스를 제공할 것입니다. DEWIN의 로드맵 및 토큰 발행 계획은 추후 공지될 예정입니다.',
      FAQ11:"저희에게 연락하는 방법？",
      FAQ11text:"커뮤니티 또는 개인이 공식 이메일 주소(support@dewin.bet)로 직접 연락하여 질문이나 개선 제안을 환영합니다.우리는 커뮤니티 거버넌스와 내생적 동기가 Dewin의 초석이라고 믿습니다.",
      china:'중국인',
      english:'영어',
      korean:'한국인',
      more:'More',
      nation:'Nation:',
      field:'Green Field:',
      club:'Club:',
      supersrar:'SuperStar:',
      fans:'Fans:',
      partner:'파트너',
      media:'미디어',
      rm:'Read More',
      roadmap:'ROADMAP',
      phrase1:'Phrase 1  Launch',
      phrasetext1:'DeWin PASS 진화 시스템 구축<br/>공식 웹사이트 및 소셜 미디어 배포<br/>기본 기능 구현 및 튜토리얼<br/>유명 기관과 협력<br/>Dewin.bet 기본 계약 배포<br/>Dewin.Bet 오라클 머신 배포<br/>Dewin Dao 커뮤니티 설정<br/>월드컵 기간 중 공식 홍보 및 홍보<br/>DeWin에볼루션 시즌 출시<br/>유명 기관과의 프로모션<br/> Dewin.bet 계약 업그레이드<br/> Dewin 패스 규칙 업그레이드<br/>주요 거래소의 Dewin 목록',
      phrase2:'Phrase 2  Growth',
      phrasetext2:'Dewin.bet 게임 플레이 업그레이드<br/>Dewin 다중 대회 모듈 계약 배포<br/>지속적인 후원 및 권위 있는 대회 참가<br/>비주류/시사/e스포츠 예측 모듈 추가<br/>상위 거래소의 Dewin 목록<br/>대출 모듈 계약 전개<br/>Dewin Pass 기능적 디자인<br/>경제 시스템 업그레이드 Dewin 기능<br/>Dewin 디플레이션 메커니즘 출시<br/>Dewin은 플레이어 독립 보너스로 후원됩니다<br/>Dewin Dao는 예측 연락처를 독립적으로 배포합니다<br/>Dewin Pass 기반 메타버스 설정',
      phrase3:'Phrase 3  Built',
      phrasetext3:'더 많은 NFT 커뮤니티 및 조직과 협력<br/>더 많은 Dewin 살포기<br/>Dewin에 자율적으로 배포된 예측 계약은 모든 핫 이벤트에 대한 첫 번째 선택이 됩니다<br/>슈퍼스타들이 Dewin 합류하다<br/>업계 내 조직과의 포괄적인 협력<br/>Dewin 결제 및 Defi 개선<br/>Dewin 주요 언론과 보도에 폭넓게 관여<br/>Dewin은 주요 핫 이벤트 예측에 적극적으로 참여합니다<br/>Dewin 계약 수익은 Dewin PASS에 보다 공평하게 분배됩니다<br/>Dewin 메타버스 개선 및 생태계 구축',
      claimtoken: '주장하다 $DEWIN',
      connectfirst: '월렛을 먼저 연결하세요',
      notinairdrop: '죄송합니다. 에어드랍 자격이 없습니다.',
      back: '돌아가다',
      claimnow: '축하합니다. 지금 에어드랍을 받을 수 있습니다.',
      claim: '주장하다',
      airdroprule: '$DEWIN 에어드랍 규칙:<br/>지금부터 시즌 1 개막식까지 최초 10,000개의 발행된 FANS PASS 주소, 즉 유효한 주소가 $DEWIN Airdrop 활동에 참여할 수 있으며 각 유효한 주소는 1,000 $DEWIN 토큰을 청구할 수 있습니다.<br/>유효한 각 주소에는 일괄적으로 청구할 수 없는 $DEWIN을 청구할 수 있는 기회가 한 번뿐입니다.<br/>$DEWIN Airdrop은 FIFA World Cup Qatar 2022™를 기념하기 위한 것으로 DeWin PASS 업그레이드 등과 관련이 없습니다.<br/>질문이 있으시면 공식 이메일 주소 <a href="mailto:support@dewin.bet">support@dewin.bet</a> 으로 문의하십시오.'
    }
};
  
 export default i18n;