import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { TransProvider } from './i18n/useI18n';
import { AppContextProvider } from './store/AppContext';
import i18n from './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

ReactDOM.render(

  <AppContextProvider>
   <TransProvider i18n={i18n}>
    <App />
  </TransProvider>
  </AppContextProvider>,
  document.getElementById("root"),
);
