const DEFAULT_CHAIN_ID_DEV = 4;
const DEFAULT_CHAIN_ID_STR_DEV = '0x4';

const DEFAULT_CHAIN_ID = 1;
const DEFAULT_CHAIN_ID_STR = '0x1';

const DEFAULT_BSC_CHAIN_ID_DEV = 97;
const DEFAULT_BSC_CHAIN_ID_STR_DEV = '0x61';

const DEFAULT_BSC_CHAIN_ID = 56;
const DEFAULT_BSC_CHAIN_ID_STR = '0x38';

const FEE_RATE_MULTIPLE_TIMES = 100000000;
const MIN_ALLAWANCE_AMOUNT = 100000;
const BTC_TO_SATOSHI = 100000000;

const ETH_RPC_PROD = {
    chainId: "0x1",
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    chainName: "MAINNET",
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    blockExplorerUrls: ["https://etherscan.io"]
};

const ETH_RPC_DEV = {
    chainId: "0x4",
    rpcUrls: ["https://rinkeby.infura.io/v3/"],
    chainName: "RINKEDBY",
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    blockExplorerUrls: ["https://rinkeby.etherscan.io"]
};

const BSC_RPC_PROD = {
    chainId: "0x38",
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    chainName: "BSC-MAINNET",
    nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18
    },
    blockExplorerUrls: ["https://bscscan.com/"]
};

const BSC_RPC_DEV = {
    chainId: "0x61",
    rpcUrls: ["https://data-seed-prebsc-2-s3.binance.org:8545/"],
    chainName: "BSC-TESTNET",
    nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18
    },
    blockExplorerUrls: ["https://testnet.bscscan.com"]
};

// user rewards refresh time interval 10s
const USER_REWARDS_REFRESH_INTERVAL = 10000;
// user time refresh time interval 1s
const USER_REMAINED_TIME_REFRESH_INTERVAL = 1000;
const WALLET_GET_GAS_REFRESH_INTERVAL = 30000;
const REMAINED_BOX_REFRESH_TIME = 100000;

const COOLHASH_BALANCE_PRECISION = 4;
const COOLGAS_BALANCE_PRECISION = 4;
const BTC_BALANCE_PRECISION = 8;

const DEFAULT_CHAIN_MAP = {
    'development' : {
        id: DEFAULT_BSC_CHAIN_ID_DEV,
        id_str : DEFAULT_BSC_CHAIN_ID_STR_DEV,
        rpc: BSC_RPC_DEV
    },
    'production' : {
        id: DEFAULT_BSC_CHAIN_ID,
        id_str : DEFAULT_BSC_CHAIN_ID_STR,
        rpc: BSC_RPC_PROD
    }
};

const CONFIG_MAP = {
    'development' : {
        encodePrefix: 'testnet',
        mempool : 'testnet/',
    },
    'production' : {
        encodePrefix: 'main',
        mempool: ''
    }
}

function getConfigs(data) {
    const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
    if (env) {
      return data[env];
    }
    return data['production'];
}

export {
    DEFAULT_CHAIN_MAP,
    FEE_RATE_MULTIPLE_TIMES,
    MIN_ALLAWANCE_AMOUNT,
    USER_REWARDS_REFRESH_INTERVAL,
    USER_REMAINED_TIME_REFRESH_INTERVAL,
    WALLET_GET_GAS_REFRESH_INTERVAL,
    REMAINED_BOX_REFRESH_TIME,
    COOLHASH_BALANCE_PRECISION,
    COOLGAS_BALANCE_PRECISION,
    BTC_BALANCE_PRECISION,
    BTC_TO_SATOSHI,
    CONFIG_MAP,
    getConfigs
}